import './_vendor';
import vars from './_vars';
import './_functions';
import './_components';

import Rellax from 'rellax';

var rellax = new Rellax('.rellax', {
  // center: true
});
if(rellax){
  window.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      try {
        rellax.refresh()
      } catch(e){
        console.log(e);
      }
    }, 70)




  })

  window.addEventListener('resize', () => {
    try {
      rellax.refresh()
    } catch(e){
      console.log(e);
    }
  })
}

const video = document.querySelector('.heating-video')
if(video){
  if(window.matchMedia('(max-width: 768px)').matches){
    const videoBtn = document.querySelector('.video-mob__btn')
    let clicked = false
    function f(){
      if (!document.fullscreenElement) {
        video.pause()
        video.volume = 0
        clicked = !clicked
        document.removeEventListener('fullscreenchange', this)
      }
    }
    videoBtn.addEventListener('click', () => {
      clicked = !clicked
        if(clicked){
          video.play()
          video.requestFullscreen()
          video.volume = 0.5
          document.addEventListener('fullscreenchange', f)
        }
    })

  } else {
    video.volume = 0
    const observer = new IntersectionObserver((entries, observer) => {
      if(entries[0].isIntersecting){
        video.play()
      } else {
        video.pause()
      }
    }, {
      threshold: 0.4
    })
    observer.observe(document.querySelector('.heating'))
  }
}




const formButtons = document.querySelectorAll('[data-form-btn], .rent__item')
formButtons.forEach(btn => {
  btn.addEventListener('click', (e) => {
    e.preventDefault()
    e.stopPropagation()

    modal.style.transform = 'translateY(0)'
  })
})

const modal = document.querySelector('[data-form-modal]')
if(modal){

  modal.addEventListener('click', e => {
    e.stopPropagation()

    modal.style.transform = null
  })
  modal.querySelector('.modal-form').addEventListener('click', e => e.stopPropagation())
}
const burgerBtn = document.querySelector('.burger')
const closeBtn = document.querySelector('.menu__close--menu')

const menu = document.querySelector('.menu')
burgerBtn.addEventListener('click', e => {
  menu.classList.add('active')

})

const navLinks = document.querySelector('.menu').querySelectorAll('.nav__link')

navLinks.forEach(el => {
  el.addEventListener('click', e => {
    e.stopPropagation()
    menu.classList.remove('active')
  })
})

closeBtn.addEventListener('click', () => {
  menu.classList.remove('active')
})

const discFormBrn = document.querySelector('.disc-form__btn')

discFormBrn?.addEventListener('click', (e) => {
  e.stopPropagation()
  const modalDisc = document.querySelector('.modal-disc')
  modalDisc.style.transform = 'translateY(0)'

  modalDisc.querySelector('.modal-form').addEventListener('click', e => e.stopPropagation())
  modalDisc.addEventListener('click', e => {
    e.stopPropagation()

    modalDisc.style.transform = null
  })
})


document.querySelector('a[href="#rel"')?.addEventListener('click', function(e) {
  e.preventDefault();
  let href = this.getAttribute('href').substring(1);
  const scrollTarget = document.getElementById(href);
  let topOffset = document.querySelector('.benefits').clientHeight / 2
  let elementPosition = scrollTarget.getBoundingClientRect().top;
  const offsetPosition = elementPosition + topOffset;
  window.scrollBy({
    top: offsetPosition,
    behavior: 'smooth'
  });
})


const mobContacts = document.querySelector('.mob-contacts')
const mobContactsList = document.querySelector('.mob-contacts>ul')
const mobContactsBtnClose = document.querySelector('.menu__close--contacts')
const mobContactsBtn = document.querySelector('.header__contact--btn')

mobContactsList?.addEventListener('click', e => {
  e.stopPropagation()
})
mobContacts?.addEventListener('click', e => {
  e.preventDefault()
  e.stopPropagation()

  mobContacts.classList.remove('active')
  setTimeout(() => {

    mobContacts.style.transform = null
  }, 300)
})

mobContactsBtn?.addEventListener('click', (e) => {
  e.preventDefault()
  e.stopPropagation()
  mobContacts.style.transform = 'translateY(0)'
  setTimeout(() => {
    mobContacts.classList.add('active')
  }, 300)
})
mobContactsBtnClose?.addEventListener('click', (e) => {
  e.preventDefault()
  e.stopPropagation()
  mobContacts.classList.remove('active')
  setTimeout(() => {

    mobContacts.style.transform = null
  }, 300)
})
