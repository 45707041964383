
import JustValidate from 'just-validate';
import Inputmask from "inputmask";
const formModal = document.querySelector('.modal-form')
const validation = new JustValidate('.modal-form');
const mask = (type, options) => { // создаем универсальную функцию
  const elements = document.querySelectorAll(`[data-type="${type}"]`) // ищем поля ввода по селектору с переданным значением data-атрибута
  if (!elements) return // если таких полей ввода нет, прерываем функцию

  elements.forEach(el => { // для каждого из полей ввода
    IMask(el, options) // инициализируем плагин imask для необходимых полей ввода с переданными параметрами маски
  })
}
mask('date', {
  mask: Date,
  min: new Date(1900, 0, 1),
})

const inputMask = new Inputmask('+7 (999) 999-99-99');
const telSelector = formModal.querySelector('input[type="tel"]');
inputMask.mask(telSelector);
validation
  .addField('#modal-name', [
    {
      rule: 'minLength',
      value: 3,
    },
    {
      rule: 'maxLength',
      value: 30,
    },
    {
      rule: 'required',
      value: true,
      errorMessage: 'Введите имя!'
    }
  ])
  .addField('#modal-check', [
    {
      rule: 'required',
      value: true
    }
  ])
  .addField('#modal-email', [
    {
      rule: 'required',
      value: true,
      errorMessage: 'Email обязателен',
    },
    {
      rule: 'email',
      value: true,
      errorMessage: 'Введите корректный Email',
    },
  ])
  .addField('#modal-phone', [
    {
      rule: 'required',
      value: true,
      errorMessage: 'Телефон обязателен',
    },
    {
      rule: 'function',
      validator: function() {
        const phone = telSelector.inputmask.unmaskedvalue();
        return phone.length === 10;
      },
      errorMessage: 'Введите корректный телефон',
    },
  ])
  .onSuccess((event) => {
      const formData = new FormData(formModal)
      formModal.classList.add('_sending')
      fetch('mail.php', {
        method: 'POST',
        body: formData
      }).then(() => {
        validation.addField('#modal-email', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Email обязателен',
          },
          {
            rule: 'email',
            value: true,
            errorMessage: 'Введите корректный Email',
          },
        ])
        .addField('#modal-phone', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Телефон обязателен',
          },
          {
            rule: 'function',
            validator: function() {
              const phone = telSelector.inputmask.unmaskedvalue();
              return phone.length === 10;
            },
            errorMessage: 'Введите корректный телефон',
          },
        ]).refresh()
        formModal.reset();
        formModal.classList.remove('_sending');
    })

  })
  const modPhone =  document.querySelector('#modal-phone')
  const modMail = document.querySelector('#modal-email')
    if(modMail.value == '' && modPhone.value == ''){
      validation.addField('#modal-email', [
        {
          rule: 'required',
          value: true,
          errorMessage: 'Email обязателен',
        },
        {
          rule: 'email',
          value: true,
          errorMessage: 'Введите корректный Email',
        },
      ]);

      validation.addField('#modal-phone', [
        {
          rule: 'required',
          value: true,
          errorMessage: 'Телефон обязателен',
        },
        {
          rule: 'function',
          validator: function() {
            const phone = telSelector.inputmask.unmaskedvalue();
            return phone.length === 10;
          },
          errorMessage: 'Введите корректный телефон',
        },
      ]);
    }
  modMail.addEventListener('change', (e) => {
    if(modMail.value != ''){
      validation.addField('#modal-email', [
        {
          rule: 'required',
          value: true,
          errorMessage: 'Email обязателен',
        },
        {
          rule: 'email',
          value: true,
          errorMessage: 'Введите корректный Email',
        },
      ]);
      validation.removeField('#modal-phone');
    } else if(modPhone.value != ''){
      validation.addField('#modal-email', [
        {
          rule: 'required',
          value: true,
          errorMessage: 'Email обязателен',
        },
        {
          rule: 'email',
          value: true,
          errorMessage: 'Введите корректный Email',
        },
      ]);
    }
     else {
      validation.removeField('#modal-email');

    }
  });

  modPhone.addEventListener('change', (e) => {
    if(modPhone.value != '') {
      validation.addField('#modal-phone', [
        {
          rule: 'required',
          value: true,
          errorMessage: 'Телефон обязателен',
        },
        {
          rule: 'function',
          validator: function() {
            const phone = telSelector.inputmask.unmaskedvalue();
            return phone.length === 10;
          },
          errorMessage: 'Введите корректный телефон',
        },
      ]);
      validation.removeField('#modal-email');
    } else if(modMail.value == ''){
      validation.addField('#modal-phone', [
        {
          rule: 'required',
          value: true,
          errorMessage: 'Телефон обязателен',
        },
        {
          rule: 'function',
          validator: function() {
            const phone = telSelector.inputmask.unmaskedvalue();
            return phone.length === 10;
          },
          errorMessage: 'Введите корректный телефон',
        },
      ]);
    }
    else {
      validation.removeField('#modal-phone');

    }
  });




const formRequest = document.querySelector('.req-form')
if(formRequest){
  const telSelectorReq = formRequest.querySelector('input[type="tel"]');
  const validationReq = new JustValidate('.req-form');
  inputMask.mask(telSelectorReq);
  validationReq
    .addField('#req-name', [
      {
        rule: 'minLength',
        value: 3,
      },
      {
        rule: 'maxLength',
        value: 30,
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Введите имя!'
      }
    ])

    .addField('#req-check', [
      {
        rule: 'required',
        value: true
      }

    ])
    .addField('#req-email', [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Email обязателен',
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Введите корректный Email',
      },
    ])
    .addField('#req-phone', [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Телефон обязателен',
      },
      {
        rule: 'function',
        validator: function() {
          const phone = telSelectorReq.inputmask.unmaskedvalue();
          return phone.length === 10;
        },
        errorMessage: 'Введите корректный телефон',
      },
    ])
    .onSuccess((event) => {
        validationReq.addField('#req-email', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Email обязателен',
          },
          {
            rule: 'email',
            value: true,
            errorMessage: 'Введите корректный Email',
          },
        ])
        .addField('#req-phone', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Телефон обязателен',
          },
          {
            rule: 'function',
            validator: function() {
              const phone = telSelectorReq.inputmask.unmaskedvalue();
              return phone.length === 10;
            },
            errorMessage: 'Введите корректный телефон',
          },
        ]).refresh()
        const formData = new FormData(formRequest)
        formRequest.classList.add('_sending')
        fetch('mail_req.php', {
          method: 'POST',
          body: formData
        }).then(() => {
          formRequest.reset();
          formRequest.classList.remove('_sending');
      })
    })




    const reqPhone =  document.querySelector('#req-phone')
    const reqMail = document.querySelector('#req-email')
      // if(reqMail.value == '' && reqPhone.value == ''){
      //   validationReq.addField('#req-email', [
      //     {
      //       rule: 'required',
      //       value: true,
      //       errorMessage: 'Email обязателен',
      //     },
      //     {
      //       rule: 'email',
      //       value: true,
      //       errorMessage: 'Введите корректный Email',
      //     },
      //   ]);

      //   validationReq.addField('#req-phone', [
      //     {
      //       rule: 'required',
      //       value: true,
      //       errorMessage: 'Телефон обязателен',
      //     },
      //     {
      //       rule: 'function',
      //       validator: function() {
      //         const phone = telSelectorReq.inputmask.unmaskedvalue();
      //         return phone.length === 10;
      //       },
      //       errorMessage: 'Введите корректный телефон',
      //     },
      //   ]);
      // }



    reqMail.addEventListener('change', (e) => {
      if(reqMail.value != ''){
        validationReq.addField('#req-email', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Email обязателен',
          },
          {
            rule: 'email',
            value: true,
            errorMessage: 'Введите корректный Email',
          },
        ]);
        validationReq.removeField('#req-phone');
      } else if(reqPhone.value != ''){
        validationReq.addField('#req-email', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Email обязателен',
          },
          {
            rule: 'email',
            value: true,
            errorMessage: 'Введите корректный Email',
          },
        ]);
      }
       else {
        validationReq.removeField('#req-email');

      }
    });


    reqPhone.addEventListener('change', (e) => {
      if(reqPhone.value != '') {
        validationReq.addField('#req-phone', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Телефон обязателен',
          },
          {
            rule: 'function',
            validator: function() {
              const phone = telSelectorReq.inputmask.unmaskedvalue();
              return phone.length === 10;
            },
            errorMessage: 'Введите корректный телефон',
          },
        ]);
        validationReq.removeField('#req-email');
      } else if(reqMail.value == ''){
        validationReq.addField('#req-phone', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Телефон обязателен',
          },
          {
            rule: 'function',
            validator: function() {
              const phone = telSelectorReq.inputmask.unmaskedvalue();
              return phone.length === 10;
            },
            errorMessage: 'Введите корректный телефон',
          },
        ]);
      }
      else {
        validationReq.removeField('#req-phone');

      }
    });

}










const discountForm = document.querySelector('.disc-form')
if(discountForm){
  const telSelectorDisc = document.querySelector('.modal-disc').querySelector('input[type="tel"]');
  inputMask.mask(telSelectorDisc);
  const validationDisc = new JustValidate('.disc-form');
  validationDisc
    .addRequiredGroup('#power-group')
    .addField('#date-rate', [
      {
        rule: 'required',
      }
    ])
    .addField('#date-rate-finish', [
      {
        rule: 'required',
      }
    ])
    .addField('#disc-address', [
      {
        rule: 'required',
      }
    ])
    .addField('#modal-name-disc', [
      {
        rule: 'minLength',
        value: 3,
      },
      {
        rule: 'maxLength',
        value: 30,
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Введите имя!'
      }
    ])
    .addField('#modal-email-disc', [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Email обязателен',
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Введите корректный Email',
      },
    ])
    .addField('#modal-phone-disc', [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Телефон обязателен',
      },
      {
        rule: 'function',
        validator: function() {
          const phone = telSelectorDisc.inputmask.unmaskedvalue();
          return phone.length === 10;
        },
        errorMessage: 'Введите корректный телефон',
      },
    ])
    .addField('#modal-check-disc', [
      {
        rule: 'required',
        value: true
      }
    ])
    .onSuccess((event) => {
        const formData = new FormData(discountForm)
        const modalDisc = document.querySelector('.modal-disc')
        discountForm.classList.add('_sending')
        fetch('mail_disc.php', {
          method: 'POST',
          body: formData
        }).then(() => {
          validationDisc.addField('#modal-email-disc', [
            {
              rule: 'required',
              value: true,
              errorMessage: 'Email обязателен',
            },
            {
              rule: 'email',
              value: true,
              errorMessage: 'Введите корректный Email',
            },
          ])
          .addField('#modal-phone-disc', [
            {
              rule: 'required',
              value: true,
              errorMessage: 'Телефон обязателен',
            },
            {
              rule: 'function',
              validator: function() {
                const phone = telSelectorDisc.inputmask.unmaskedvalue();
                return phone.length === 10;
              },
              errorMessage: 'Введите корректный телефон',
            },
          ]).refresh()


          discountForm.reset();
          discountForm.classList.remove('_sending');
          modalDisc.querySelectorAll('input').forEach(el => {
            el.value = ''
          })
          modalDisc.style.transform = null
      })
    })


    const discPhone =  document.querySelector('#modal-phone-disc')
    const discMail = document.querySelector('#modal-email-disc')
      // if(modMail.value == '' && modPhone.value == ''){
      //   validationDisc.addField('#modal-email', [
      //     {
      //       rule: 'required',
      //       value: true,
      //       errorMessage: 'Email обязателен',
      //     },
      //     {
      //       rule: 'email',
      //       value: true,
      //       errorMessage: 'Введите корректный Email',
      //     },
      //   ]);

      //   validationDisc.addField('#modal-phone', [
      //     {
      //       rule: 'required',
      //       value: true,
      //       errorMessage: 'Телефон обязателен',
      //     },
      //     {
      //       rule: 'function',
      //       validator: function() {
      //         const phone = telSelector.inputmask.unmaskedvalue();
      //         return phone.length === 10;
      //       },
      //       errorMessage: 'Введите корректный телефон',
      //     },
      //   ]);
      // }
    discMail.addEventListener('change', (e) => {
      if(discMail.value != ''){
        validationDisc.addField('#modal-email-disc', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Email обязателен',
          },
          {
            rule: 'email',
            value: true,
            errorMessage: 'Введите корректный Email',
          },
        ]);
        validationDisc.removeField('#modal-phone-disc');
      } else if(discPhone.value != ''){
        validationDisc.addField('#modal-email-disc', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Email обязателен',
          },
          {
            rule: 'email',
            value: true,
            errorMessage: 'Введите корректный Email',
          },
        ]);
      }
       else {
        validationDisc.removeField('#modal-email-disc');

      }
    });

    discPhone.addEventListener('change', (e) => {
      if(discPhone.value != '') {
        validationDisc.addField('#modal-phone-disc', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Телефон обязателен',
          },
          {
            rule: 'function',
            validator: function() {
              const phone = telSelectorDisc.inputmask.unmaskedvalue();
              return phone.length === 10;
            },
            errorMessage: 'Введите корректный телефон',
          },
        ]);
        validationDisc.removeField('#modal-email-disc');
      } else if(discMail.value == ''){
        validationDisc.addField('#modal-phone-disc', [
          {
            rule: 'required',
            value: true,
            errorMessage: 'Телефон обязателен',
          },
          {
            rule: 'function',
            validator: function() {
              const phone = telSelectorDisc.inputmask.unmaskedvalue();
              return phone.length === 10;
            },
            errorMessage: 'Введите корректный телефон',
          },
        ]);
      }
      else {
        validationDisc.removeField('#modal-phone-disc');

      }
    });
}
